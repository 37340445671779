<template>
  <v-row class="flex-row align-self-stretch">
    <v-navigation-drawer expand-on-hover v-model="drawer" color="purple darken-1"
     absolute dark permanent floating>
      <v-list dense>

        <!-- lien menu seul -->
        <v-list-item link :to="`/profile`">
          <v-list-item-icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Retour au tableau de bord</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- fin lien menu seul -->

        <!-- lien menu seul -->
        <v-tooltip max-width="300" :position-x="50" right>
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" link to="/transversal">
              <v-list-item-icon>
                <v-icon>mdi-apps</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Comparaison d'analyse</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Comparaison manuelle des analyses entre patients</span>
        </v-tooltip>
        <!-- fin lien menu seul -->

        <!-- lien menu seul -->
        <v-tooltip max-width="300" :position-x="50" right>
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" link to="/transversal/groups">
              <v-list-item-icon>
                <v-icon>mdi-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Significativité</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Comparaison par groupe, score de significativité</span>
        </v-tooltip>
        <!-- fin lien menu seul -->

        <!-- lien menu seul -->
        <v-tooltip max-width="300" :position-x="50" right>
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" disabled link to="/transversal/prosody">
              <v-list-item-icon>
                <v-icon disabled>mdi-timer</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Prosodie</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Comparaison des temps de réaction</span>
        </v-tooltip>
        <!-- fin lien menu seul -->

        <!-- lien menu seul -->
        <v-tooltip max-width="300" :position-x="50" right>
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" link to="/transversal/interviews">
              <v-list-item-icon>
                <v-icon>mdi-text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Entretiens</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Comparaison d'entretiens</span>
        </v-tooltip>
        <!-- fin lien menu seul -->
      </v-list>
    </v-navigation-drawer>

    <v-col cols="12" class="pt-0">
      <router-view @notify="snack"></router-view>
    </v-col>
  </v-row>
</template>

<script>
import MixinInterviews from '@/mixins/interviews';

export default {
  name: 'Interview',
  mixins: [MixinInterviews],

  data: () => ({
    drawer: true,
    mini: true,
  }),
  methods: {
    snack(e) {
      this.$emit('notify', e);
    },
  },
};
</script>
